<template>
  <div>
    <Row :gutter="8" class="m-b-10">
      <i-col span="4">
        <RadioGroup v-model="type" type="button" size="small" @on-change="handleChangeType">
          <Radio v-for="item in typeArray" :key="'type_' + item.value" :label="item.value">{{ item.name }}</Radio>
        </RadioGroup>
      </i-col>
      <i-col span="3">
        <Select v-model="assetIds" multiple size="small" placeholder="选择资产">
          <Option v-for="item in assetArray" :key="'assetId_' + item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="2" v-show="type === 1">
        <Select v-model="query.categoryId" :clearable="true" size="small" placeholder="选择类目"
          @on-change="handleChangeCategory">
          <Option v-for="item in categoryArray" :key="'category_' + item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="2">
        <Select v-model="query.productId" :clearable="true" size="small" placeholder="选择产品">
          <Option v-for="item in productArray" :key="'productId_' + item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="2">
        <Select v-model="query.companyId" clearable size="small" placeholder="选择代理商">
          <Option v-for="item in agentComArray" :key="'agentCom_' + item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="2">
        <Select v-model="query.enabled" :clearable="true" size="small" placeholder="是否可用">
          <Option v-for="item in enabledArray" :key="'enabled_' + item.value" :value="item.value">{{ item.name }}
          </Option>
        </Select>
      </i-col>
      <i-col span="4">
        <i-input v-model="query.keyword" size="small" placeholder="输入关键字"></i-input>
      </i-col>
      <i-col span="5">
        <i-button size="small" class="m-r-5" type="primary" icon="ios-search" @click="handleSearch">搜索</i-button>
        <i-button size="small" class="m-r-5" type="success" @click="handleAddProduct">新增产品</i-button>
        <i-button size="small" class="m-r-5" type="primary" @click="onShowResServiceConfig">资源服务费配置</i-button>
        <i-button size="small" class="m-r-5" type="primary" @click="onShowEleMediaConfig">电子媒体规则配置</i-button>
      </i-col>
    </Row>
    <div class="m-b-10">
      <Button type="primary" size="small" :disabled="selectedItems.length === 0" @click="onSetAgentCom">分配代理商</Button>
    </div>
    <Table size="small" stripe :max-height="700" :data="list" :columns="tableColumns" :loading="tableLoading"
      @on-selection-change="onSelection"></Table>
    <div class="paging_style">
      <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator :current="query.pageNumber"
        @on-change="handlePageChange"></Page>
    </div>
    <component ref="productModify" :is="addProductComponent" :projectId="projectId" :saveSuccessFun="initData">
    </component>
    <template v-if="type === 4">
      <ThemeSvg ref="themeSvg"></ThemeSvg>
    </template>
    <template else>
      <Modal v-model="diagramModal" :width="1000" footer-hide>
        <p slot="header" class="text-center">查看产品图示</p>
        <ProductDiagram ref="productDiagram"></ProductDiagram>
      </Modal>

    </template>
    <SetServiceCost ref="setServiceCost" :publisherId="userInfo.publisherId" :saveSuccessFun="initData" />
    <EleMediaConfig ref="eleMediaConfig" :publisherId="userInfo.publisherId" :projectId="projectId" />
    <ResServiceConfig ref="resServiceConfig" :publisherId="userInfo.publisherId" />
    <choice-resource v-if="showChoiceRes" ref="choiceResource" :assetIds="choicedAssetIds" :skuId="productSkuid"
      :projectId="projectId" />
    <set-agent-com v-if="setAgentModal" ref="setAgentCom" :projectId="projectId" :selectedSkuIds="selectedItems"
      :onSuccess="onSetAgentComSuccess" />
  </div>
</template>

<script>
import { productMixins } from '../mixins/product'
import { toMoney } from '@/utils/wnumb_own'

// import { getMaintenanceAssetList } from '@/api/rim/asset'
import { getPublisherProductAssetList } from '@/api/product/asset'
import { allCateory } from '@/api/product/category'
import { getCommonPage, getFixPage, getCustomPage } from '@/api/product/productskuedit'
import { getProjectCompany } from '@/api/os/project'

import ThemeSvg from './ThemeSvg.vue'
import ProductDiagram from './ProductDiagram.vue'
import SetServiceCost from './SetServiceCost.vue'
import EleMediaConfig from './EleMediaConfig.vue'
import ResServiceConfig from './ResServiceConfig/List'
import ChoiceResource from './common/ChoiceResource.vue'
import SetAgentCom from './common/SetAgentCom.vue'
export default {
  mixins: [productMixins],
  props: {
    projectId: [Number]
  },
  components: {
    AddRoutine: () => import('./AddRoutine.vue'),
    AddTheme: () => import('./AddTheme.vue'),
    AddCustom: () => import('./AddCustom.vue'),
    ThemeSvg,
    ProductDiagram,
    SetServiceCost,
    EleMediaConfig,
    ResServiceConfig,
    ChoiceResource,
    SetAgentCom
  },
  data () {
    return {
      type: 1,
      addProductComponent: 'AddRoutine',
      typeArray: [
        { value: 1, name: '常规媒体' },
        { value: 4, name: '主题媒体' },
        { value: 7, name: '套装媒体' }
      ],
      enabledArray: [
        { value: 1, name: '可用' },
        { value: 0, name: '不可用' }
      ],
      // assetId: null,
      assetIds: [],
      query: {
        assetIds: [],
        categoryId: null,
        productId: null,
        enabled: 1,
        keyword: '',
        pageSize: 15,
        pageNumber: 1,
        projectId: null,
        companyId: null

      },
      assetArray: [],
      categoryArray: [],
      productArray: [],
      list: [],
      total: 0,
      tableColumns: [],
      showTableColumns: new Map([
        [1, ['selection', 'id', 'name', 'fee', 'fee28', 'statementPrice', 'trainProduceCost', 'asset', 'level', 'enabled', 'minday', 'bufferDay', 'agentCom', 'desc', 'updateTime', 'operation']], // 常规媒体
        [4, ['selection', 'id', 'name', 'fee', 'fee28', 'statementPrice', 'trainProduceCost', 'asset', 'enabled', 'minday', 'bufferDay', 'agentCom', 'desc', 'updateTime', 'operation']], // 主题媒体
        [7, ['selection', 'id', 'name', 'fee', 'fee28', 'statementPrice', 'asset', 'enabled', 'minday', 'agentCom', 'desc', 'updateTime', 'operation']] // 套装媒体
      ]),
      productSkuid: null,
      diagramModal: false,
      tableLoading: false,
      showChoiceRes: false,
      choicedAssetIds: [],
      selectedItems: [],
      setAgentModal: false,
      agentComArray: []
      // productSkuAssetIds: []
    }
  },
  computed: {
    opreate: {
      set (val) {
        this.$store.commit('set_op_type', val)
      },
      get () {
        return this.$store.state.productManage.opType
      }
    }
  },
  created () {
    this.query.projectId = this.projectId
    this.initAssetArray()
    this.getCategoryData()
    this.getAgentComData()
  },
  mounted () {
    this.tableColumns = this.getTableColumns()
  },
  methods: {
    /**
     * 动态表单
     */
    getTableColumns () {
      const talbeColumns = {
        selection: { type: 'selection', width: 60, align: 'center', fixed: 'left' },
        id: { title: '主键', minWidth: 80, align: 'center', width: '80', key: 'id' },
        name: { title: '名称', minWidth: 100, align: 'center', key: 'name' },
        fee: {
          renderHeader: (h) => {
            return h('span', this.type === 1 ? '媒介使用费（块/天）' : '媒介使用费（套/天）')
          },
          minWidth: 100,
          align: 'center',
          key: 'usePrice',
          render: (h, params) => {
            return h('span', {
              attrs: {
                class: 'money'
              }
            }, toMoney(params.row.usePrice))
          }
        },
        fee28: {
          renderHeader: (h) => {
            return h('span', this.type === 1 ? '媒介使用费（4周）' : '媒介使用费（4周）')
          },
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            return h('span', {
              attrs: {
                class: 'money'
              }
            }, toMoney(params.row.usePrice * 28))
          }
        },
        statementPrice: {
          renderHeader: (h) => {
            return h('span', this.type === 1 ? '最低限价（块/天）' : '最低限价（套/天）')
          },
          align: 'center',
          minWidth: 100,
          key: 'usePrice',
          render: (h, params) => {
            return h('span', {
              attrs: {
                class: 'money'
              }
            }, toMoney(params.row.statementPrice))
          }
        },
        trainProduceCost: {
          title: '服务费',
          align: 'center',
          minWidth: 160,
          key: 'produceCost',
          render: (h, params) => {
            if (params.row.producePrice && params.row.producePrice.producePrices.length) {
              const html = []
              params.row.producePrice.producePrices.forEach(item => {
                html.push(h('p', {
                  attrs: {
                    class: 'money'
                  }
                }, `${item.name}-刊例价：${toMoney(item.produceCost)}/${item.unitName}-结算价：${toMoney(item.produceStatement)}/${item.unitName}`))
              })
              return html
            } else {
              return h('span', {
                attrs: {
                  class: 'money'
                }
              }, '/')
            }
          }
        },
        produceCost: {
          title: '媒介服务费（套/次）',
          align: 'center',
          minWidth: 100,
          key: 'produceCost',
          render: (h, params) => {
            return h('span', {
              attrs: {
                class: 'money'
              }
            }, toMoney(params.row.produceCost))
          }
        },
        asset: {
          title: '所属资产',
          align: 'center',
          minWidth: 120,
          render: (h, params) => {
            const html = []
            params.row.assets.forEach(item => {
              html.push(h('p', item.name))
            })
            return h('div', html)
          }
        },
        level: { title: '等级', minWidth: 100, align: 'center', key: 'levelName' },
        enabled: {
          title: '是否可用',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            return h('span', params.row.enabled ? '可用' : '不可用')
          }
        },
        minday: { title: '最小连续售卖天数', minWidth: 100, align: 'center', key: 'minday' },
        bufferDay: { title: '施工缓冲期（天）', minWidth: 100, align: 'center', key: 'bufferDay' },
        updateTime: { title: '更新时间', minWidth: 110, align: 'center', key: 'updateTime' },
        desc: { title: '备注', tooltip: true, minWidth: 100, align: 'center', key: 'desc' },
        agentCom: {
          title: '代理商',
          minWidth: 200,
          align: 'center',
          render: (h, { row }) => {
            if (row.companyNames && row.companyNames.length) {
              const html = []
              row.companyNames.forEach(item => {
                html.push(h('p', item))
              })
              return h('div', html)
            } else {
              return h('span', '/')
            }
          }
        },
        operation: {
          title: '操作',
          align: 'center',
          fixed: 'right',
          minWidth: 210,
          render: (h, params) => {
            const html = []
            html.push(
              h('a', {
                on: {
                  click: () => {
                    this.handleEdit(params.row)
                  }
                }
              }, '编辑')
            )
            if ([4].includes(this.type)) {
              // 常规媒体和主题媒体
              html.push(
                h('a', {
                  class: ['m-l-5'],
                  on: {
                    click: () => {
                      this.onViewRes(params.row)
                    }
                  }
                }, '管理资源')
              )
            }
            if ([1, 4].includes(this.type)) {
              // 常规媒体和主题媒体
              html.push(
                h('a', {
                  class: ['m-l-5'],
                  on: {
                    click: () => {
                      this.onSetServiceCost(params.row)
                    }
                  }
                }, '配置服务费')
              )
            }
            // 主题媒体产品
            html.push(
              h('a', {
                class: ['m-l-5'],
                on: {
                  click: () => {
                    this.handleViewDiagram(params.row)
                  }
                }
              }, '查看图示')
            )
            return h('div', html)
          }
        }
      }
      const data = []
      var showColumns = this.showTableColumns.get(this.type)
      if (!showColumns || showColumns.length === 0) {
        showColumns = this.showTableColumns.get(1)
      }
      showColumns.forEach(col => data.push(talbeColumns[col]))

      return data
    },
    initData () {
      switch (this.type) {
        case 1:
          // 常规媒体
          this.getRoutineMediumData()
          this.addProductComponent = 'AddRoutine'
          break
        case 4:
          // 主题媒体
          this.getFixMediumData()
          this.addProductComponent = 'AddTheme'
          break
        case 7:
          // 套装媒体
          this.getCustomMediumData()
          this.addProductComponent = 'AddCustom'
          break
      }
    },
    /**
    * 获取类别数据
    */
    getCategoryData () {
      allCateory({ type: this.type }).then(res => {
        if (res && !res.errcode) {
          this.categoryArray = res
        }
        this.$store.commit('set_category_array', this.categoryArray)
      })
    },
    handleChangeType () {
      // this.query.assetId = null
      this.tableColumns = this.getTableColumns()
      this.query.assetIds = []
      this.query.productId = null
      this.query.categoryId = null
      this.query.enabled = 1
      this.query.keyword = ''
      this.query.pageNumber = 1
      if (this.type === 1) {
        this.productArray = []
      } else {
        this.getProductData({ categoryId: this.type })
      }
      this.initData()
    },
    /**
     * 切换类别筛选条件
     */
    handleChangeCategory () {
      if (!this.query.categoryId) {
        this.productArray = []
      } else {
        this.getProductData({ categoryId: this.query.categoryId })
      }
    },
    /**
     * 分页获取常规媒体数据
     */
    getRoutineMediumData () {
      this.tableLoading = true
      const assetIds = this.assetIds.length === 0 ? this.assetArray.map(x => x.id) : this.assetIds
      this.query.assetIds = JSON.stringify(assetIds)
      getCommonPage(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }
      }).finally(() => { this.tableLoading = false })
    },
    /**
     * 分页获取主题媒体数据
     */
    getFixMediumData () {
      this.tableLoading = true
      const assetIds = this.assetIds.length === 0 ? this.assetArray.map(x => x.id) : this.assetIds
      this.query.assetIds = JSON.stringify(assetIds)
      getFixPage(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }
      }).finally(() => { this.tableLoading = false })
    },
    /**
     * 分页获取套装媒体数据
     */
    getCustomMediumData () {
      this.tableLoading = true
      const assetIds = this.assetIds.length === 0 ? this.assetArray.map(x => x.id) : this.assetIds
      this.query.assetIds = JSON.stringify(assetIds)
      getCustomPage(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }
      }).finally(() => { this.tableLoading = false })
    },
    // 初始化资产选项，并默认选中第一条
    initAssetArray () {
      getPublisherProductAssetList().then((res) => {
        if (res && !res.errcode) {
          this.assetArray = res
          // if (this.assetArray.length) {
          //   this.assetId = this.assetArray[0].id
          // }
        }
        this.$store.commit('set_asset_array', this.assetArray)
      }).then(() => {
        this.initData()
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.initData()
    },
    handlePageChange (curPage) {
      this.query.pageNumber = curPage
      this.initData()
    },
    handleAddProduct () {
      this.opreate = 1
      this.$refs.productModify.initShow()
    },
    handleEdit (params) {
      this.opreate = 2
      this.$refs.productModify.initShow(params)
    },
    /**
     * 管理资源
     */
    onViewRes (params) {
      this.productSkuid = params.id
      this.choicedAssetIds = params.assetIdList
      this.showChoiceRes = true
      this.$nextTick(() => {
        this.$refs.choiceResource.showModal()
      })
    },
    handleViewDiagram (params) {
      if (this.type === 4) { // 主题媒体
        this.$refs.themeSvg.showModal(params)
      } else {
        this.$refs.productDiagram.initData(params.id)
        this.diagramModal = true
      }
    },
    onSetServiceCost (params) {
      // this.productSkuAssetIds = params.assetIdList
      this.$nextTick(() => {
        this.$refs.setServiceCost.init(params)
      })
    },
    onShowEleMediaConfig () {
      this.$refs.eleMediaConfig.init()
    },
    onShowResServiceConfig () {
      this.$refs.resServiceConfig.init()
    },
    onSelection (selections) {
      this.selectedItems = selections.map(x => x.id)
    },
    /**
     * 分配代理商
     */
    onSetAgentCom () {
      this.setAgentModal = true
      this.$nextTick(() => {
        this.$refs.setAgentCom.showModal()
      })
    },
    onSetAgentComSuccess () {
      this.selectedItems = []
      this.initData()
    },
    /**
     * 获取代理商数据
     */
    async getAgentComData () {
      const result = await getProjectCompany({ projectId: this.projectId, type: 3 })
      this.agentComArray = (result && !result.errcode) ? result : []
    }
  }
}
</script>
